import { theme } from 'styles';

export const productsContainer = {
  padding: '3em 19.25em',
  marginBottom: 48,

  '.title': {
    fontWeight: 400,
    fontSize: 54,
    color: theme.black,
    marginBottom: 48,
    textAlign: 'center',

    '@media (max-width: 576px)': {
      fontSize: 40,
      padding: '0.5em',
    },
  },

  '.column': {
    width: '100%',
    display: 'flex',

    '.product-image': {
      width: '100%',

      '@media (max-width: 767px)': {
        padding: 0,
        maxHeight: 600,
      },

      '@media (max-width: 576px)': {
        padding: 0,
      },
    },

    '.product-info-container': {
      padding: '0 2em 0 4em',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      margin: '2em 0',

      '.product-title': {
        textTransform: 'uppercase',
        fontSize: 20,
        color: theme.black,
        fontWeight: 400,
        marginBottom: 12,
      },

      '.price-icon-quantity-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 24,

        '.product-price': {
          fontSize: 22,
          marginRight: 8,
          color: theme.black,
          fontWeight: 400,
        },

        '.info-tooltip': {
          marginRight: 8,

          '.icon': {
            color: theme.black,
          },
        },

        '.product-quantity': {
          fontSize: 22,
          color: theme.black56,
          fontWeight: 400,
        },
      },

      '.product-description': {
        fontSize: 18,
        fontWeight: 400,
        color: theme.gray300,
        marginBottom: 32,

        '@media (max-width: 4000px) and (min-width: 1920px)': {
          fontSize: 26,
        },
      },

      '.action-container': {
        textTransform: 'uppercase',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        width: '100%',

        '.button-default': {
          flex: 1,
          color: theme.black,
          border: `1px solid ${theme.grayLight}`,

          '&:active': { outline: 'none' },
        },

        '.button-link': {
          flex: 1,
          color: theme.black,
          textTransform: 'uppercase',

          '&:active': { outline: 'none' },
        },
      },

      '@media (max-width: 992px)': {
        padding: '0 1em 0 2em',
      },

      '@media (max-width: 767px)': {
        padding: '0 2em',
      },

      '@media (max-width: 576px)': {
        padding: '0em 3em',
      },
    },
  },

  '@media (max-width: 1199px)': {
    padding: '3em 9.25em',
  },

  '@media (max-width: 992px)': {
    padding: '3em 7.25em',
  },

  '@media (max-width: 767px)': {
    padding: '3em 5em',
  },

  '@media (max-width: 576px)': {
    padding: '3em 0em',
  },
};

export const contentInfoTooltipContainer = {
  '& .tooltip-content': {
    width: 250,
  },
};
