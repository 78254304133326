import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import { getText } from '@veraio/strank';
import { Button, Col, ColorBatch, Icon, Image, Row, BecomeAffiliateCart } from 'components';
import { ROUTES } from 'enums';
import { brandIngredients } from './config';
import { brandStoryContainer } from './styles';

const BrandStory = () => {
  const [muteVideo, setMuteVideo] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div css={brandStoryContainer}>
      <div className="header-container">
        <h3 className="title">{getText('smallButMightyUnlockPower')}</h3>
        <p className="subtitle">{getText('fromCoreOfTheCellAndTheDepths')}</p>
        <Image
          src="https://oneecosystem.blob.core.windows.net/vita/apps/images/brand-story-header.jpg"
          alt="brand-story-image"
          className="brand-image"
        />
      </div>

      <div className="content-container">
        <div className="small-container">
          <div className="small-container-content">
            <h4 className="content-title">{getText('smallButMighty')}</h4>
            <p className="content-subtitle">{getText('withPrecisionOfScienceAndCareful')}</p>
          </div>
          <div className="video-container">
            <ReactPlayer
              className="video-styles"
              url="https://vimeo.com/1012753449/37b06d9de3?ts=0&share=copy"
              playing={true}
              loop={true}
              muted={muteVideo}
            />
            <Icon
              iconName={muteVideo ? 'volume_off' : 'volume_up'}
              className="unmute-icon"
              color="white"
              onClick={() => setMuteVideo(!muteVideo)}
            />
          </div>
        </div>

        <Row gap={24} className="reversible-container">
          <Col xl={6} lg={6} md={12} xs={12}>
            <Image
              src="https://oneecosystem.blob.core.windows.net/vita/apps/images/all-products-night-cream2.jpg"
              alt="brand-all-products-image"
              className="product-image"
            />
          </Col>
          <Col xl={6} lg={6} md={12} xs={12} className="text-content">
            <h4 className="content-title">{getText('products')}</h4>
            <p className="content-subtitle">{getText('productsProvideCompleteDailySkinCare')}</p>
            <Button type="default" className="button-default" linkTo={ROUTES.Products}>
              {getText('exploreProducts')}
            </Button>
          </Col>
        </Row>
      </div>

      <div className="ingredients-container">
        <Image
          src="https://oneecosystem.blob.core.windows.net/vita/apps/images/brand-story-women-formulated.jpg"
          alt="brand-story-women-formulated-image"
          className="background-formulated-image"
        />

        <div className="text-content-ingredients">
          <h4 className="ingredients-content-title">{getText('formulatedWithHighTech')}</h4>
          <div className="batch-container">
            {brandIngredients.map((el, ind) => (
              <ColorBatch withoutStartCase type="default" key={ind} className="batch-styles">
                {getText(el.text)}
              </ColorBatch>
            ))}
          </div>
          <Button type="default" className="button-default-ingredients" linkTo={ROUTES.Ingredients}>
            {getText('readMoreAboutTheIngredients')}
          </Button>
        </div>
      </div>

      <BecomeAffiliateCart />
    </div>
  );
};

export default BrandStory;
