import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/lazy';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { getText } from '@veraio/strank';
import {
  Button,
  Col,
  Icon,
  Image,
  Row,
  BecomeAffiliateCart,
  CarouselProducts,
  IngredientsSlider,
  ProductSliderCart,
  useWindowSize,
} from 'components';
import { ALL_PRODUCTS_MOCK_DATA, ROUTES, ROUTINE_PRODUCTS_DATA } from 'enums';
import { ingredientsData } from './config';
import tabletThumbnail from 'assets/images/thumbnails/tabletThumbnail-2160x1728.jpg';
import mobileThumbnail from 'assets/images/thumbnails/mobileThumbnail-2160x3480.jpg';
import desktopThumbnail from 'assets/images/thumbnails/desktopThumbnail-3840x2160.jpg';
import { homePageContainer, thumbnailImageStyles } from './styles';

const carouselResponsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
};

const HomePage = ({ isMobile }) => {
  const windowWidth = useWindowSize(window.innerWidth, window.innerHeight);
  const tabletWidth = 992 > windowWidth.width && windowWidth.width > 767;
  const notTabletNotMobile = 767 > windowWidth.width && windowWidth.width > 576;
  const mobileWidth = windowWidth.width < 576;
  const [scrollPosition, setScrollPosition] = useState(0);
  const [focussedVideo, setFocussedVideo] = useState(false);
  const [muteVideo, setMuteVideo] = useState(true);
  const [showVideo, setShowVideo] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowVideo(true);
      setFocussedVideo(true);
    }, 5600);
  }, []);

  useEffect(() => {
    const handleScroll = () => setScrollPosition(window.scrollY);
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (scrollPosition > 660) window.onscroll = () => setFocussedVideo(false);
    if (scrollPosition < 660) window.onscroll = () => setFocussedVideo(true);
  }, [scrollPosition]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div css={homePageContainer(isMobile)}>
      <div className="video-container">
        <ReactPlayer
          style={{ display: showVideo ? 'flex' : 'none' }}
          url={
            mobileWidth
              ? 'https://vimeo.com/1012780622/ca26b5e0fb'
              : tabletWidth || isMobile
              ? 'https://vimeo.com/1012779936/4b66854c70'
              : 'https://vimeo.com/1012788606/045ec5fe20'
          }
          height={
            windowWidth.width > 1399
              ? 'calc(100vh - 60px)'
              : notTabletNotMobile
              ? 'auto'
              : tabletWidth
              ? '100%'
              : '100%'
          }
          width="100vw"
          playing={focussedVideo}
          loop={true}
          muted={muteVideo}
          config={{
            vimeo: {
              playerOptions: {
                autoplay: true,
                fullscreen: true,
                responsive: true,
                thumbnailUrl: 'https://oneecosystem.blob.core.windows.net/vita/apps/images/about.svg',
              },
            },
          }}
        />
        <Icon
          iconName={muteVideo ? 'volume_off' : 'volume_up'}
          className="unmute-icon"
          color="white"
          onClick={() => setMuteVideo(!muteVideo)}
        />
        <Image
          css={thumbnailImageStyles(windowWidth.width > 1399, notTabletNotMobile, tabletWidth, isMobile, showVideo)}
          src={
            mobileWidth
              ? mobileThumbnail ??
                'https://oneecosystem.blob.core.windows.net/vita/apps/images/mobileThumbnail-2160x3480.jpg'
              : tabletWidth || isMobile
              ? tabletThumbnail ??
                'https://oneecosystem.blob.core.windows.net/vita/apps/images/tabletThumbnail-2160x1728.jpg'
              : desktopThumbnail ??
                'https://oneecosystem.blob.core.windows.net/vita/apps/images/desktopThumbnail-3840x2160.jpg'
          }
          alt="thumbnail"
        />
      </div>
      <div className="crystal-image-container">
        <Image
          src="https://oneecosystem.blob.core.windows.net/vita/apps/images/silver-crystal.svg"
          alt="silver-crystal"
          className="crystal-image"
        />
        <div className="text-over-crystal-container">
          <p className="title-company">{getText('oneVita')}</p>
          <h4 className="title-beauty">{getText('secretToTimelessBeauty')}</h4>
          <p className="description">{getText('homeProductsDescription')}</p>
          <Button type="secondary" className="secondary-button" linkTo={ROUTES.Products}>
            {getText('exploreProducts')}
          </Button>
        </div>
      </div>

      <div className="from-nature-container">
        <p className="upper-text">{getText('elevateYourGlow')}</p>
        <h4 className="title">{getText('healthAndBeautyFromNature')}</h4>
        <Carousel
          infinite
          pauseOnHover
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          containerClass="product-slider"
          itemClass="column-cart"
          responsive={carouselResponsive}>
          {ALL_PRODUCTS_MOCK_DATA?.map((el, ind) => (
            <Col key={ind} horizontalGap={16}>
              <ProductSliderCart product={el} />
            </Col>
          ))}
        </Carousel>
      </div>

      <Row className="about-container">
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <Image
            src="https://oneecosystem.blob.core.windows.net/vita/apps/images/about.svg"
            alt="about-image"
            className="about-image"
          />
        </Col>
        <Col xl={6} lg={6} md={6} sm={12} xs={12} className="about-info">
          <p className="upper-text">{getText('aboutOneVita')}</p>
          <h4 className="title">{getText('smallButMighty')}</h4>
          <p className="description-about">{getText('aboutVitaDescriptionHomePage')}</p>
          <Button type="secondary" className="about-secondary-button" linkTo={ROUTES.BrandStory}>
            {getText('ourStory')}
          </Button>
        </Col>
      </Row>

      <div className="routine-steps-container">
        <p className="upper-text">{getText('fiveStepSystem')}</p>
        <h4 className="title">{getText('forYouthfulSkin')}</h4>
        <CarouselProducts products={ROUTINE_PRODUCTS_DATA} isMobile={isMobile} />
      </div>

      <div className="secrets">
        <Row className="secrets-container" horizontalGap={24}>
          <Col xl={4} lg={4} md={12} xs={12}>
            <h4 className="title-secrets">{getText('theSecretOfOneVita')}</h4>
          </Col>
          <Col xl={8} lg={8} md={12} xs={12} className="secrets-description-container">
            <p className="description-secrets">{getText('revolutionaryMicroSizedMolecules')}</p>
          </Col>
        </Row>
        <IngredientsSlider ingredients={ingredientsData} />
        <div className="secrets-button-container">
          <Button type="secondary" className="secondary-button" linkTo={ROUTES.Ingredients}>
            {getText('learnMore')}
          </Button>
        </div>
      </div>

      <BecomeAffiliateCart />
    </div>
  );
};

export default HomePage;
