import { useRef, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { getText } from '@veraio/strank';
import { printPrice, useCurrencies } from '@oneecosystem/dealshaker-core';
import {
  Button,
  Col,
  Icon,
  Image,
  // REMOVE TAG HIDE FOR FIRST VERSION
  // Input,
  Modal,
  PriceLabel,
  Row,
  Tooltip,
  BecomeAffiliateCart,
} from 'components';
import { ALL_PRODUCTS_MOCK_DATA, DEFAULT_CURRENCY } from 'enums';
import { productDetailsContainer, ingredientsModalContainer, contentInfoTooltipContainer } from './styles';

const ProductDetails = () => {
  const history = useHistory();
  const params = useParams();
  const selectedCurrency = useCurrencies((currenciesState) => currenciesState?.selectedCurrency);
  const productId = params?.productId ?? history.location?.state?.productId;
  const productInfoDetails = ALL_PRODUCTS_MOCK_DATA?.find((el) => el?.id === Number(productId));
  const productInfo = { ...history.location?.state, ...productInfoDetails };
  // REMOVE TAG HIDE FOR FIRST VERSION
  // const [quantity, setQuantity] = useState(1);
  // const inputRef = useRef();
  const [selectedIngredient, setSelectedIngredient] = useState([]);
  const modalRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [productId]);

  const convertedToLocalCurrencyPrice = (price) =>
    price && (
      <PriceLabel
        fiat={Number(price.priceFiat)}
        fiatOriginCurrency={DEFAULT_CURRENCY.code}
        fiatDesiredCurrency={selectedCurrency?.code}
        crypto={price.priceCrypto}
      />
    );

  const handleOpenIngredientsModal = (value) => {
    modalRef.current?.open();
    setSelectedIngredient(value);
  };

  return (
    <>
      <div css={productDetailsContainer(productInfo?.isRightInfo)}>
        <div className="top-image-container">
          <Image src={productInfo?.coverImage} alt="cover" className="top-image" />

          <div className="add-card">
            <h3 className="product-title">{getText(productInfo?.title)}</h3>
            <div className="price-icon-quantity-container">
              <p className="product-price">
                {printPrice({
                  fiat: Number(productInfo?.totalPrice),
                  fiatOriginCurrency: DEFAULT_CURRENCY.code,
                  fiatDesiredCurrency: selectedCurrency?.code,
                })}
              </p>
              <Tooltip
                content={convertedToLocalCurrencyPrice(productInfo)}
                placement="bottom"
                noArrow
                contentClassName={contentInfoTooltipContainer}
                className="info-tooltip">
                <Icon iconName="info" size={16} color="black" />
              </Tooltip>
              <p className="product-quantity">
                {productInfo?.numberOfQuantity
                  ? getText('quantityMl', {
                      quantity: productInfo?.quantity,
                      numberOfQuantity: productInfo?.numberOfQuantity,
                    })
                  : getText('ml', { quantity: productInfo?.quantity })}
              </p>
            </div>
            <div className="action-container">
              {/* REMOVE TAG HIDE FOR FIRST VERSION
            <Input
              ref={inputRef}
              type="number"
              className="input"
              value={1}
              onChange={() => setQuantity(inputRef?.current?.value)}
            /> */}
              <Button
                small
                type="default"
                className="button-default"
                onClick={() => window.open(productInfo.dealshakerProductUrl, '_blank')}>
                {getText('buyNow')}
              </Button>
            </div>
          </div>
        </div>

        <div className="product-container">
          <Row horizontalGap={32}>
            <Col xl={5} lg={5} md={12} xs={12} className="column">
              <Image src={productInfo?.introducingImage} alt="product" className="product-image" />
            </Col>
            <Col xl={7} lg={7} md={12} xs={12} className="column-info">
              <div className="product-info-container">
                <p className="introducing-style">{getText('introducing')}</p>
                <h3 className="product-title">{getText(productInfo?.title)}</h3>
                <p className="product-description">{getText(productInfo?.productDetailsDescription)}</p>
                <ul className="product-ingredients">
                  {productInfo?.productIngredients?.map((el, ind) => (
                    <li key={ind}>{getText(el)}</li>
                  ))}
                </ul>

                <div className="icons-container">
                  <Image
                    src="https://oneecosystem.blob.core.windows.net/vita/apps/images/no-perfume-icon.png"
                    alt="no-perfume"
                    width={40}
                    height={40}
                    className="icon-image"
                  />
                  <span className="natural">{getText('noPerfume')}</span>
                  <Image
                    src="https://oneecosystem.blob.core.windows.net/vita/apps/images/natural-preservative-icon.png"
                    alt="natural-preservative"
                    width={38}
                    height={40}
                    className="icon-image"
                  />
                  <span className="natural">{getText('bioActiveIngredients')}</span>
                </div>
              </div>
            </Col>
          </Row>
        </div>

        <div className="product-container">
          <Row horizontalGap={32} className="reversible-container">
            <Col xl={7} lg={7} md={12} xs={12} className="ingredients-column-info">
              <div className="product-info-container">
                <h3 className="product-title">{getText('keyIngredients')}</h3>
                <p className="product-description">{getText(productInfo?.keyIngredients)}</p>
                <Button
                  small
                  type="default"
                  className="buy-button"
                  onClick={() => window.open(productInfo.dealshakerProductUrl, '_blank')}>
                  {getText('buyNow')}
                </Button>
              </div>
            </Col>
            <Col xl={5} lg={5} md={12} xs={12} className="column">
              <Image src={productInfo?.keyIngredientsImage} alt="product" className="product-image" />
            </Col>
          </Row>
        </div>

        <Image src={productInfo?.allProductsImage} alt="products" />

        <div className="more-information-container">
          <div className="more-info-title-container">
            <h3 className="more-info-title">{getText('moreInformation')}</h3>
            <a href={productInfo?.downloadPdfLink} download="pdf" target="_blank" rel="noreferrer">
              <Icon iconName="download" color="black" size={40} />
            </a>
          </div>

          <Row gap={16}>
            {productInfo?.ingredients?.map((el, ind) => (
              <Col xl={4} lg={4} md={6} sm={6} xs={12} key={ind} className="ingredients-cart">
                <div className="ingredients-cart-container">
                  <Image src={el.icon} alt="more" className="more-image" />
                  <p className="more-info">
                    <span className="more-title">{getText(el.title)}</span>
                    {getText(el?.description)}
                    {(el?.ingredientsList?.length > 0 || el?.subDescription) && (
                      <Icon
                        iconName="info"
                        size={16}
                        color="black"
                        className="more-icon"
                        onClick={() => handleOpenIngredientsModal(el)}
                      />
                    )}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
        </div>

        <BecomeAffiliateCart />
      </div>

      <Modal ref={modalRef} closeIcon css={ingredientsModalContainer} className="ingredients-cart">
        <Image src={selectedIngredient.icon} alt="more" className="more-image" />

        <div className="scrollable-container">
          <p className="more-info">
            <span className="more-title">{getText(selectedIngredient.title)}</span>
            {`${getText(selectedIngredient?.description)} ${getText(selectedIngredient?.subDescription)}`}
          </p>
          {selectedIngredient?.ingredientsList?.length && <hr className="horizontal-line" />}
          <ul className="list">
            {selectedIngredient?.ingredientsList?.map((element, index) => (
              <li key={index}>
                <p className="more-info-ingredients">
                  <span className="more-title-ingredients">{getText(element.title)}</span>
                  {getText(element?.description)}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </Modal>
    </>
  );
};

export default ProductDetails;
