import { theme } from 'styles';

export const productDetailsContainer = (isRightInfo) => ({
  '.top-image-container': {
    height: 700,
    padding: '0em 9.25em',
    position: 'relative',

    '.top-image': {
      objectFit: 'cover',
      zIndex: 1,
      height: '100%',

      '@media (max-width: 992px)': {
        objectFit: 'fill',
      },

      '@media (max-width: 767px)': {
        height: 500,
      },

      '@media (max-width: 576px)': {
        objectFit: 'contain',
        height: 370,
      },
    },

    '.add-card': {
      position: 'absolute',
      maxWidth: 400,
      bottom: 50,
      right: isRightInfo && 180,
      left: !isRightInfo && 180,
      zIndex: 10,
      padding: '3em',
      backgroundColor: theme.white,
      boxShadow: theme.boxShadow,

      '.price-icon-quantity-container': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: 24,

        '.product-price': {
          fontSize: 22,
          marginRight: 8,
          color: theme.black,
          fontWeight: 400,
        },

        '.info-tooltip': {
          marginRight: 8,
        },

        '.product-quantity': {
          fontSize: 22,
          color: theme.black56,
          fontWeight: 400,
        },
      },

      '.action-container': {
        textTransform: 'uppercase',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        flexWrap: 'wrap',
        width: '100%',

        '.input': {
          width: 100,
          marginRight: 8,
        },
      },

      '.button-default': {
        backgroundColor: theme.black,
        color: theme.white,
        border: `1px solid ${theme.grayLight}`,
        padding: '0.5em 3em',
        height: '4.9rem',
        textTransform: 'uppercase',
        width: '100%',

        '&:active': { outline: 'none' },

        '@media (max-width: 576px)': {
          padding: '0.5em 1.5em',
        },
      },

      '@media (max-width: 992px)': {
        maxWidth: 300,
      },

      '@media (max-width: 767px)': {
        position: 'relative',
        maxWidth: '100%',
        bottom: 0,
        right: isRightInfo && 0,
        left: !isRightInfo && 0,
        margin: '3em',
      },

      '@media (max-width: 576px)': {
        margin: '0em ',
      },
    },

    '@media (max-width: 4000px) and (min-width: 1920px)': {
      height: 1300,
      padding: '0em 9.25em',
    },

    '@media (max-width: 1920px)': {
      height: 900,
      padding: '0em 9.25em',
    },

    '@media (max-width: 1520px)': {
      height: 700,
      padding: '0em 9.25em',
    },

    '@media (max-width: 1399px)': {
      height: 600,
      padding: '0em 9.25em',
    },

    '@media (max-width: 992px)': {
      height: 600,
      padding: '0em 7em',
    },

    '@media (max-width: 767px)': {
      height: '100%',
      padding: '0em',
    },

    '@media (max-width: 576px)': {
      height: '100%',
      padding: 0,
    },
  },

  '.product-title': {
    textTransform: 'uppercase',
    fontSize: 20,
    color: theme.black,
    fontWeight: 400,
    marginBottom: 12,
  },

  '.product-info-container': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',

    '.introducing-style': {
      fontWeight: 400,
      fontSize: 18,
      marginBottom: 8,
      color: theme.black,
    },

    '.product-description': {
      fontSize: 24,
      fontWeight: 300,
      color: theme.gray800,
      marginBottom: 20,
      lineHeight: 1.7,

      '@media (max-width: 1520px)': {
        fontSize: 16,
      },
    },

    '.product-ingredients': {
      padding: '4px 32px',
      fontSize: 16,
      fontWeight: 300,
      color: theme.gray800,
      marginBottom: 20,
      lineHeight: 1.7,
    },

    '.icons-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',

      '.icon-image': {
        marginRight: 8,
      },

      '.natural': {
        marginRight: 16,
        fontSize: 14,
        fontWeight: 400,
      },
    },

    '.buy-button': {
      backgroundColor: theme.black,
      color: theme.white,
      border: `1px solid ${theme.grayLight}`,
      padding: '0.5em 3em',
      height: '4.9rem',
      textTransform: 'uppercase',

      '&:active': { outline: 'none' },

      '@media (max-width: 576px)': {
        width: '100%',
      },
    },
  },

  '.product-container': {
    padding: '3em 11.25em',
    marginTop: 40,

    '.column': {
      position: 'relative',
      display: 'flex',
      flex: 1,

      '.product-image': {
        position: 'relative',
        objectFit: 'contain',

        '@media (max-width: 1680px) and (min-width: 992px)': {
          objectFit: 'cover',
        },
      },
    },

    '.column-info': {
      display: 'flex',
      marginLeft: 32,

      '@media (max-width: 992px)': {
        padding: 0,
        paddingTop: 24,
        marginLeft: 0,
      },
    },

    '.reversible-container': {
      '.ingredients-column-info': {
        display: 'flex',
        flexDirection: 'column',

        '@media (max-width: 992px)': {
          padding: 0,
          paddingTop: 24,
          flexDirection: 'column-reverse',
        },
      },

      '@media (max-width: 992px)': {
        flexDirection: 'column-reverse',
      },
    },

    '@media (max-width: 767px)': {
      padding: '3em 5em',
    },
  },

  '.more-information-container': {
    padding: '3em 11.25em',
    backgroundColor: theme.secondary,

    '.more-info-title-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 40,

      '.more-info-title': {
        fontWeight: 400,
        fontSize: 48,
        color: theme.black,
        marginRight: 32,
      },
    },

    '.ingredients-cart': {
      padding: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',

      '.ingredients-cart-container': {
        marginBottom: 8,

        '.more-image': {
          width: 40,
          height: 40,
          marginBottom: 16,
          objectFit: 'contain',
        },

        '.more-info': {
          fontSize: 16,
          fontWeight: 300,
          color: theme.gray300,
          lineHeight: 1.4,

          '.more-title': {
            fontSize: 16,
            fontWeight: 700,
            color: theme.black,
            textTransform: 'uppercase',
            marginRight: 4,
            lineHeight: 1.4,
          },

          '.more-icon': {
            marginLeft: 4,
          },
        },
      },

      '.button-link': {
        width: 'fit-content',

        '&:active': { outline: 'none' },
      },
    },

    '@media (max-width: 767px)': {
      padding: '3em',
    },
  },
});

export const ingredientsModalContainer = {
  padding: 20,
  display: 'flex',
  flexDirection: 'row',

  '.scrollable-container': {
    height: 500,
    overflow: 'auto',
  },

  '.scrollable-container::-webkit-scrollbar': {
    display: 'none',
  },

  '.horizontal-line': {
    backgroundColor: theme.gray200,
  },

  '.more-image': {
    width: 40,
    height: 40,
    marginBottom: 16,
    objectFit: 'contain',
  },

  '.more-info': {
    fontSize: 16,
    fontWeight: 300,
    color: theme.gray300,
    lineHeight: 1.4,

    '.more-title': {
      fontSize: 16,
      fontWeight: 700,
      color: theme.black,
      textTransform: 'uppercase',
      marginRight: 4,
      lineHeight: 1.4,
    },
  },

  '.list': {
    padding: '4px 24px',

    '.more-info-ingredients': {
      fontSize: 16,
      fontWeight: 300,
      color: theme.gray300,
      lineHeight: 1.4,

      '.more-title-ingredients': {
        fontSize: 16,
        fontWeight: 700,
        color: theme.black,
        marginRight: 4,
        lineHeight: 1.4,
      },
    },
  },
};

export const contentInfoTooltipContainer = {
  '& .tooltip-content': {
    width: 250,
  },
};
