import { theme } from 'styles';

export const brandStoryContainer = {
  position: 'relative',

  '.header-container': {
    width: '100%',
    height: 650,
    backgroundColor: theme.secondary,
    padding: '4em 15.25em',
    position: 'relative',

    '.title': {
      fontSize: 38,
      fontWeight: 400,
      color: theme.black,
      marginBottom: 16,
      textAlign: 'center',

      '@media (max-width: 767px)': {
        fontSize: 28,
      },

      '@media (max-width: 576px)': {
        fontSize: 22,
        padding: '0 1em',
      },
    },

    '.subtitle': {
      fontSize: 18,
      fontWeight: 300,
      color: theme.gray800,
      textAlign: 'center',

      '@media (max-width: 767px)': {
        fontSize: 16,
      },

      '@media (max-width: 576px)': {
        padding: '0 1em 1em',
      },
    },

    '.brand-image': {
      width: '100vw',
      height: 'auto',
      objectPosition: '25% 5%',
      position: 'absolute',
      top: 240,
      left: 0,
      right: 0,

      '@media (max-width: 767px)': {
        top: 280,
      },

      '@media (max-width: 576px)': {
        position: 'unset',
      },
    },

    '@media (max-width: 992px)': {
      height: 600,
      padding: '4em',
    },

    '@media (max-width: 767px)': {
      height: 550,
      paddingTop: '7em',
    },

    '@media (max-width: 576px)': {
      height: 450,
      padding: 0,
      paddingTop: '7em',
    },
  },

  '.text-content': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',

    '.content-title': {
      fontSize: 34,
      fontWeight: 400,
      color: theme.black,
      marginBottom: 16,
    },

    '.content-subtitle': {
      fontSize: 16,
      fontWeight: 300,
      color: theme.gray300,
      lineHeight: 1.5,
      marginBottom: 16,
    },

    '.button-default': {
      color: theme.black,
      border: `1px solid ${theme.grayLight}`,
      width: 'fit-content',
      textTransform: 'uppercase',
      padding: '1em 3em',

      '&:active': { outline: 'none' },
    },
  },

  '.image-content': {
    display: 'grid',
    justifyContent: 'flex-end',

    '@media (max-width: 1199px)': {
      justifyContent: 'center',
      width: '100%',
    },
  },

  '.content-container': {
    padding: '25.25em 9.25em 0',
    display: 'flex',
    flexDirection: 'column',

    '.small-container': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginBottom: 48,

      '.video-container': {
        position: 'relative',

        '.unmute-icon': {
          position: 'absolute',
          bottom: 10,
          left: 10,
        },

        '.video-styles': {
          '@media (max-width: 576px)': {
            width: '400px !important',
            height: '220px !important',
          },
        },
      },

      '.small-container-content': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        marginRight: 32,
        width: '50%',

        '.content-title': {
          fontSize: 34,
          fontWeight: 400,
          color: theme.black,
          marginBottom: 16,
        },

        '.content-subtitle': {
          fontSize: 16,
          fontWeight: 300,
          color: theme.gray300,
          lineHeight: 1.5,
          marginBottom: 16,
        },

        '@media (max-width: 992px)': {
          width: '100%',
        },

        '@media (max-width: 576px)': {
          marginRight: 0,
        },
      },

      '@media (max-width: 992px)': {
        flexWrap: 'wrap',
      },

      '@media (max-width: 576px)': {
        marginBottom: 8,
      },
    },

    '.reversible-container': {
      marginBottom: 84,

      '.product-image': {
        height: 440,
      },

      '@media (max-width: 992px)': {
        flexDirection: 'column-reverse',
        marginBottom: 24,
      },

      '@media (max-width: 576px)': {
        marginBottom: 0,
      },
    },

    '@media (max-width: 4000px) and (min-width: 1920px)': {
      padding: '25.25em 25.25em 0',
    },

    '@media (max-width: 1199px)': {
      padding: '15.25em 5.25em 0',
    },

    '@media (max-width: 992px)': {
      padding: '10.25em 5.25em',
      paddingBottom: '4em',
    },

    '@media (max-width: 576px)': {
      padding: '3em 1em',
      paddingBottom: 0,
    },
  },

  '.ingredients-container': {
    position: 'relative',

    '.background-formulated-image': {
      objectFit: 'cover',
      zIndex: 1,
      position: 'absolute',
      bottom: 0,

      '@media (max-width: 992px)': {
        position: 'unset',
      },
    },

    '.text-content-ingredients': {
      paddingBottom: '5em',
      paddingLeft: '9.25em',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '50%',
      zIndex: 1000,
      position: 'absolute',
      top: '30%',
      left: 0,
      right: 0,
      bottom: 0,

      '.ingredients-content-title': {
        fontSize: 28,
        fontWeight: 400,
        color: theme.black,
        marginBottom: 16,

        '@media (max-width: 4000px) and (min-width: 1920px)': {
          fontSize: 48,
        },

        '@media (max-width: 1440px) and (min-width: 676px)': {
          fontSize: 28,
        },

        '@media (max-width: 576px)': {
          fontSize: 24,
        },
      },

      '.batch-container': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginBottom: 32,

        '.batch-styles': {
          margin: '4px 8px',
          color: theme.primaryLight,
          textTransform: 'uppercase',
          border: 'none',
          padding: 12,
          fontSize: 14,

          '@media (max-width: 4000px) and (min-width: 1920px)': {
            fontSize: 24,
          },

          '@media (max-width: 1600px)': {
            fontSize: 20,
          },

          '@media (max-width: 1440px) and (min-width: 676px)': {
            fontSize: 14,
          },

          '@media (max-width: 576px)': {
            fontSize: 14,
          },
        },
      },

      '.button-default-ingredients': {
        color: theme.black,
        border: `1px solid ${theme.grayLight}`,
        width: 'fit-content',
        textTransform: 'uppercase',
        padding: '1em 3em',

        '&:active': { outline: 'none' },

        '@media (max-width: 4000px) and (min-width: 1920px)': {
          fontSize: 24,
        },

        '@media (max-width: 1600px)': {
          fontSize: 20,
        },

        '@media (max-width: 1440px) and (min-width: 676px)': {
          fontSize: 14,
        },

        '@media (max-width: 576px)': {
          fontSize: 14,
        },
      },

      '@media (max-width: 4000px) and (min-width: 1920px)': {
        width: '40%',
        top: '10%',
      },

      '@media (max-width: 1024px)': {
        width: '60%',
        top: '30%',
      },

      '@media (max-width: 992px)': {
        paddingBottom: '3em',
        width: '100%',
        position: 'relative',
        paddingTop: '3em',
        paddingLeft: '5.25em',
        backgroundColor: theme.secondary,
      },

      '@media (max-width: 576px)': {
        padding: '1em',
      },
    },

    '.image-content': {
      display: 'grid',
      justifyContent: 'flex-end',
      paddingTop: 0,

      '.image-formulated': {
        objectFit: 'contain',
        maxHeight: 600,
        width: '100%',

        '@media (max-width: 992px)': {
          display: 'none',
        },
      },
    },

    '@media (max-width: 4000px) and (min-width: 1920px)': {
      height: 1450,
    },

    '@media (max-width: 1680px)': {
      height: 950,
    },

    '@media (max-width: 1600px)': {
      height: 900,
    },

    '@media (max-width: 1440px) ': {
      height: 800,
    },

    '@media (max-width: 1280px) ': {
      height: 700,
    },

    '@media (max-width: 992px)': {
      height: 'auto',
      position: 'unset',
    },
  },
};
