import { theme } from 'styles';

export const discoverBeautyContainer = {
  padding: '3em 15.25em ',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',

  '.action-button': {
    textTransform: 'uppercase',
    marginBottom: 36,
    backgroundColor: 'transparent',
    color: theme.gray500,
    border: `1px solid ${theme.grayLight}`,
    fontSize: 16,
    fontWeight: 400,
    width: 'fit-content',

    '&:active': { outline: 'none' },

    '@media (max-width: 767px)': {
      width: '100%',
    },
  },

  '.discover-beauty-first-col': {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 32px 32px 0',
    maxWidth: '40%',

    '.discover-beauty-title': {
      fontSize: 38,
      fontWeight: 400,
      color: theme.black,
      marginBottom: 16,
    },

    '.discover-beauty-subtitle': {
      fontSize: 18,
      fontWeight: 300,
      color: theme.gray900,
      lineHeight: 1.5,
      marginBottom: 16,
    },

    '.first-image': {
      height: 545,
      objectFit: 'fill',

      '@media (max-width: 4000px) and (min-width: 1920px)': {
        height: 616,
        objectFit: 'cover',
        objectPosition: '0% 75%',
      },

      '@media (max-width: 1680px)': {
        height: 590,
      },

      '@media (max-width: 1440px)': {
        height: 520,
      },

      '@media (max-width: 1199px)': {
        height: 540,
      },

      '@media (max-width: 992px)': {
        height: 520,
      },

      '@media (max-width: 767px)': {
        objectFit: 'contain',
        height: 550,
      },
    },

    '@media (max-width: 767px)': {
      maxWidth: '100%',
      margin: '0 0 32px 0',
    },
  },

  '.discover-beauty-second-col': {
    display: 'flex',
    flexDirection: 'column',

    '.discover-beauty-second-image': {
      marginBottom: 32,
      height: 400,

      '@media (max-width: 767px)': {
        height: 310,
        width: '100%',
        objectFit: 'contain',
      },
    },

    '.discover-beauty-third-image': {
      height: 400,
      objectPosition: '95% 100%',

      '@media (max-width: 767px)': {
        height: 310,
        marginBottom: 32,
        objectFit: 'contain',
      },
    },

    '@media (max-width: 767px)': {
      width: '100%',
      margin: '0 0 32px 0',
    },
  },

  '@media (max-width: 1199px)': {
    padding: '3em 6.25em',
  },

  '@media (max-width: 992px)': {
    padding: '3em',
  },

  '@media (max-width: 767px)': {
    flexWrap: 'wrap',
  },
};
